<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <loading
      :active.sync="client_info_loading"
      :is-full-page="false"
    />
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
      >
        <v-toolbar-title>
          <span
            style="cursor:pointer;"
            @click="$router.push({ name: 'eKYCAlliance' })"
          >{{ $t('ekyc.listing.title') }}</span>
          <v-icon>mdi-chevron-right</v-icon>
          <span
            class="subtitle-1 grey--text text--darken-0"
            :style="request_info ? { cursor: 'pointer' } : {}"
            @click="request_info = null"
          >
            <!-- <v-skeleton-loader type="heading" style="display: inline-block;min-width: 150px;"></v-skeleton-loader> -->
            {{ client_info ? client_info.name : '' }}
          </span>
          <template v-if="request_info">
            <v-icon>mdi-chevron-right</v-icon>
            <span class="subtitle-1 grey--text text--darken-0">{{ request_info.data.bank | x500_name('O') }}</span>
          </template>
        </v-toolbar-title>
        <v-spacer />
      </v-app-bar>
    </v-card-title>
    <v-card-text v-show="!request_info">
      <v-tabs v-model="tab">
        <v-tab key="profile">
          Profile
        </v-tab>
        <v-tab key="requests">
          Requests
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="profile">
          <v-card flat>
            <v-card-title>Company Information</v-card-title>
            <v-card-text v-if="client_info">
              <v-row>
                <v-col cols="3">
                  Client Number
                </v-col>
                <v-col
                  cols="9"
                  class="primary--text"
                >
                  {{ client_info.id }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  Company Name
                </v-col>
                <v-col
                  cols="9"
                  class="primary--text"
                >
                  {{ client_info.name }}
                </v-col>
              </v-row>
              <!-- <v-row>
								<v-col cols="3">COI Number</v-col>
								<v-col cols="9" class="primary--text">{{  }}</v-col>
							</v-row> -->
              <v-row>
                <v-col cols="3">
                  BR Number
                </v-col>
                <v-col
                  cols="9"
                  class="primary--text"
                >
                  {{ client_info.brNumber }}
                </v-col>
              </v-row>
              <!-- <v-row>
								<v-col cols="3">Profile Create Date</v-col>
								<v-col cols="9" class="primary--text">{{  }}</v-col>
							</v-row> -->
              <v-row>
                <v-col cols="3">
                  Company
                </v-col>
                <v-col cols="9">
                  <v-progress-circular
                    indeterminate
                    :size="25"
                    :width="2"
                    v-if="company_info_loading"
                  />
                  <v-btn
                    color="primary"
                    outlined
                    v-else-if="company_info"
                    :to="{ name: 'CompanyEdit', params: { customer_id: company_info.id } }"
                  >
                    <v-icon left>
                      mdi-account-details
                    </v-icon>
                    Company Info
                  </v-btn>
                  <div
                    v-else
                    class="error--text"
                  >
                    <v-icon class="error--text">
                      mdi-alert-circle
                    </v-icon>
                    Record not find (RBA)
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title>Attachments</v-card-title>
            <v-card-text>
              <!-- <template v-for="(val, key) in [1,2]">
								{{ val }}
								<eKycAllianceDocument :key="key" ref="documentTable" :ekyc_client_id="client_info ? client_info.id : ''"/>
							</template> -->
              <eKycAllianceDocument
                ref="documentTable"
                :ekyc-client-id="client_info ? client_info.id : ''"
                :company-id="company_info ? company_info.id : ''"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="requests">
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="auto">
                  <v-btn
                    color="primary"
                    outlined
                    :to="{ name: 'eKYCAllianceNew' }"
                  >
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    Create Request
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
              <loading
                :active.sync="listing_request_loading"
                :is-full-page="false"
              />
              <v-server-table
                v-if="client_info"
                class="pt-0"
                ref="table"
                name="ekyc/requestTable"
                :columns="listing_request_columns"
                :options="listing_request_options(client_info ? client_info.id : 0)"
              >
                <div
                  slot="bank_name"
                  slot-scope="props"
                >
                  {{ props.row.data.bank | x500_name('O') }}
                </div>
                <div
                  slot="status"
                  slot-scope="props"
                >
                  {{ props.row.data.status }}
                </div>
                <div
                  slot="request_date"
                  slot-scope="props"
                >
                  <DateFormat
                    :date="props.row.data.createdAt"
                    :showtime="true"
                  />
                </div>
                <!-- <div slot="select" slot-scope="props">
									<v-checkbox v-model="step2.files" multiple :value="props.row.id"></v-checkbox>
								</div> -->
                <!-- <div slot="create_date" slot-scope="props">
									<DateFormat :date="props.row.create_date" :showtime="true"/>
								</div> -->
                <div
                  slot="action"
                  slot-scope="props"
                >
                  <v-btn
                    icon
                    @click="request_info = props.row"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
              </v-server-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-text v-if="request_info">
      <v-row no-gutters>
        <v-col cols="8">
          <v-card-title class="pt-0">
            Company Information
            <v-spacer />
            <v-dialog
              max-width="350"
              v-model="dialog_withdraw"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  :loading="withdrawing"
                  :disabled="request_info.data.status != 'NEW'"
                >
                  Withdraw
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-title>Withdraw Confirmation</v-card-title>
                  <v-card-text>Are you sure to withdraw this request?</v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      text
                      color="error"
                      @click="onWithdrawClicked"
                      :loading="withdrawing"
                    >
                      Yes, Withdraw
                    </v-btn>
                    <v-btn
                      text
                      color="darken-1"
                      @click="dialog.value = false"
                    >
                      No
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
            <!-- <v-btn outlined color="error" @click="onWithdraw"
							:loading="withdrawing"
							:disabled="request_info.data.status != 'NEW'">Withdraw</v-btn> -->
          </v-card-title>
          <v-card-text v-if="client_info">
            <v-row>
              <v-col cols="3">
                Client Number
              </v-col>
              <v-col
                cols="9"
                class="primary--text"
              >
                {{ request_info.data.clientId }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                Company Name
              </v-col>
              <v-col
                cols="9"
                class="primary--text"
              >
                {{ request_info.clientName }}
              </v-col>
            </v-row>
            <!-- <v-row>
							<v-col cols="3">COI Number</v-col>
							<v-col cols="9" class="primary--text">{{  }}</v-col>
						</v-row> -->
            <v-row>
              <v-col cols="3">
                BR Number
              </v-col>
              <v-col
                cols="9"
                class="primary--text"
              >
                {{ request_info.brNumber }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                Request Number
              </v-col>
              <v-col
                cols="9"
                class="primary--text"
              >
                {{ request_info.id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                Request Status
              </v-col>
              <v-col
                cols="9"
                class="primary--text"
              >
                {{ request_info.data.status }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                Request Date
              </v-col>
              <v-col
                cols="9"
                class="primary--text"
              >
                <DateFormat
                  :date="request_info.data.createdAt"
                  :showtime="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-title>
            Files
            <!-- <div class="caption--text">select file to share</div> -->
            <!-- <v-btn outlined color="primary" class="mx-3"><v-icon left>mdi-plus</v-icon>Add Files</v-btn> -->
          </v-card-title>
          <v-card-text>
            <eKycAllianceDocument
              :table-name="'ekyc/documentShareTable'"
              :ekyc-client-id="client_info ? client_info.id : ''"
              mode="sharing"
              :ekyc-request-ref="request_info.ref"
              :shared-items="request_info.data.docLinearIds"
              @shared="({ ekyc_document_id, request_info: new_request_info }) => {
                request_info = new_request_info;
                $refs.table.getData();
              }"
              @unshared="({ ekyc_document_id, request_info: new_request_info }) => {
                request_info = new_request_info;
                $refs.table.getData();
              }"
            />
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <v-card
            style="height: 100%;max-height: 650px;"
            class="blue lighten-5"
          >
            <v-toolbar class="blue lighten-5">
              <v-toolbar-title class="mx-3">
                CHAT
              </v-toolbar-title>
            </v-toolbar>
            <!-- <v-virtual-scroll :items="request_messages" :item-height="98" height="500">
							<template v-slot:default="{ item }">
								<v-row>
									<v-col cols="7"
										:offset="$options.filters.x500_name(item.sender, 'OU') == 'TCSP' ? 5 : 0">
										<v-card
											:dark="$options.filters.x500_name(item.sender, 'OU') == 'TCSP'"
											:color="$options.filters.x500_name(item.sender, 'OU') == 'TCSP' ? 'blue-grey darken-4' : 'grey lighten-1'">
											<v-card-text>
												<div>{{ item.message }}</div>
												<v-row no-gutters>
													<v-spacer></v-spacer>
													<v-col cols="auto" class="text-caption">
														<DateFormat :date="item.createdAt" :showtime="true"/>
													</v-col>
												</v-row>
											</v-card-text>
										</v-card>
									</v-col>
								</v-row>
							</template>
						</v-virtual-scroll> -->
            <v-card-text
              style="height: calc(100% - 64px - 64px); overflow: auto;position:relative;"
              xclass="d-flex align-end flex-column"
              ref="messages_box"
              v-scroll.self="onScroll"
              @mousewheel="onScroll"
            >
              <v-row
                v-for="(message, key) in request_messages"
                :key="key"
              >
                <v-col
                  cols="7"
                  :offset="$options.filters.x500_name(message.sender, 'OU') == 'TCSP' ? 5 : 0"
                >
                  <v-card
                    :dark="$options.filters.x500_name(message.sender, 'OU') == 'TCSP'"
                    :color="$options.filters.x500_name(message.sender, 'OU') == 'TCSP' ? 'blue-grey darken-4' : 'grey lighten-1'"
                  >
                    <v-card-text>
                      <div>{{ message.message }}</div>
                      <v-row no-gutters>
                        <v-spacer />
                        <v-col
                          cols="auto"
                          class="text-caption"
                        >
                          <DateFormat
                            :date="message.createdAt"
                            :showtime="true"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-btn
              color="primary"
              style="position: absolute;bottom: 68px;right: 22px;"
              v-show="user_scroll_up"
              fab
              small
              @click="user_scroll_up = false"
            >
              <v-icon>mdi-format-vertical-align-bottom</v-icon>
            </v-btn>
            <v-card-actions>
              <v-text-field
                solo
                hide-details
                placeholder="Write a message ..."
                v-model="new_message"
                @keyup.enter="onSendClicked"
              >
                <template v-slot:append>
                  <v-btn
                    icon
                    color="primary"
                    @click="onSendClicked"
                    :loading="new_message_sending"
                  >
                    <v-icon style="transform: rotate(-45deg) translate(2px,1px);">
                      mdi-send
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DateFormat from '@/components/common/DateFormat'
import eKycAllianceDocument from '@/components/forms/eKycAllianceDocument'

export default {
  components: {
    DateFormat,
    eKycAllianceDocument
  },
  computed: {
    ...mapState('ekyc', {
      listing_request_loading: state => state.listing_request.requestLoading,
      listing_request_columns: state => state.listing_request.columns
    }),
    ...mapGetters('ekyc', {
      listing_request_options: 'listing_request_options'
    })
  },
  watch: {
    request_info () {
      this.get_messages()
    },
    tab (new_tab) {
      switch (new_tab) {
        case 0:	// profile tab
          if (this.$refs.documentTable) { this.$refs.documentTable.refresh() }
          break
        case 1:	// requests tab
          if (this.$refs.table) { this.$refs.table.refresh() }
          break
        default:
          break
      }
    }
  },
  data () {
    return {
      tab: null,
      // records
      client_info_loading: false,
      client_info: null,
      company_info_loading: false,
      company_info: null,
      request_info: null,
      // withdraw request
      withdrawing: false,
      dialog_withdraw: false,
      // messages
      request_messages: [],
      new_message: '',
      new_message_sending: false,
      user_scroll_up: false,
      interval_scroll_bottom: null,
      interval_check_message: null,
      message_checking: false
    }
  },
  methods: {
    load_ekyc_client_info (ekyc_client_id) {
      this.client_info_loading = true
      this.$client.ekyc.client.findOne(ekyc_client_id)
        .then((results) => {
          if (results.status === 200) {
            this.client_info = results.data
            return this.$notify({
              title: 'Load Client Profile',
              text: 'Client profile loaded',
              type: 'success'
            })
          }
          return this.$notify({
            title: 'Load Client Profile',
            text: 'Failed to load client profile',
            type: 'error'
          })
        })
        .catch((unusedError) => {
          this.$notify({
            title: 'Load Client Profile',
            text: 'Failed to load client profile',
            type: 'error'
          })
        })
        .finally(() => {
          this.client_info_loading = false
        })
    },
    loadCompanyInfo (ekyc_client_id) {
      this.company_info_loading = true
      this.$client.corporate.findWhere({
        where: {
          ekyc_client_id: ekyc_client_id
        },
        select: 'id',
        populate: 'names'
      })
        .then((results) => {
          if (results.data && results.data.length >= 1) {
            this.company_info = results.data[0]
          }
        })
        .catch((unusedError) => {

        })
        .finally(() => {
          this.company_info_loading = false
        })
    },
    onWithdrawClicked () {
      this.withdrawing = true
      this.$client.ekyc.request.update(this.request_info.ref, [], [], true)
        .then(({ data }) => {
          this.$refs.table.getData()
          this.request_info = data
          this.dialog_withdraw = false
        })
        .catch((unusedError) => {

        })
        .finally(() => {
          this.withdrawing = false
        })
    },
    get_messages () {
      if (!this.request_info) { return }
      if (this.message_checking) { return }
      this.message_checking = true
      this.$client.ekyc.thread.findOne(this.request_info.id)
        .then((results) => {
          this.user_scroll_up = false
          this.request_messages = results.data.messages
        })
        .catch((unusedError) => {

        })
        .finally(() => {
          this.message_checking = false
        })
    },
    scrollBottomIfNeeded () {
      if (this.user_scroll_up) { return }
      if (this.$refs.messages_box) { this.$refs.messages_box.scrollTop = this.$refs.messages_box.scrollHeight }
    },
    onScroll () {
      this.user_scroll_up = this.$refs.messages_box.scrollTop < this.$refs.messages_box.scrollHeight - this.$refs.messages_box.clientHeight
    },
    onSendClicked () {
      if (!this.new_message || !this.request_info) {
        return
      }
      this.new_message_sending = true
      this.$client.thread.create(this.request_info.id, this.new_message)
        .then((unusedResults) => {
          this.new_message = ''
        })
        .catch((unusedError) => {

        })
        .finally(() => {
          this.new_message_sending = false
        })
    },
    checkMessageIfNeeded () {
      if (this.request_info) {
        this.get_messages()
      }
    }
    // onSelectFile () {

    // }
  },
  mounted () {
    this.loadCompanyInfo(this.$route.params.ekyc_client_id)
    this.load_ekyc_client_info(this.$route.params.ekyc_client_id)
    this.interval_scroll_bottom = setInterval(this.scrollBottomIfNeeded, 500)
    this.interval_check_message = setInterval(this.checkMessageIfNeeded, 2000)
  },
  beforeDestroy () {
    clearInterval(this.interval_scroll_bottom)
    clearInterval(this.interval_check_message)
  }
}
</script>

<style>
	td {word-break: break-all;}
</style>
